import React from "react"
import { Link } from "gatsby"

import Layout from "../layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const FormCrsConversationStartersPage = () => {
  const pdfUrl = `/Form-CRS-Conversation-Starters.pdf?v=${new Date().getTime()}`
  return (
    <Layout>
      <SEO title="Form CRS Conversation Starters" />
      <Container>
        <h2 className="text-center font-size-base">
          Ant Money Advisors, LLC (“AMA”, “we”, “us”, “our”) Form CRS <br />
          Relationship Summary Responses to “Conversation Starters” <br />
          Updated March 16, 2022
        </h2>
        <p className="font-italic">
          <strong>Conversation starters:</strong> Given my financial situation,
          should I choose an investment advisory service? Why or why not? How
          will you choose investments to recommend to me? What is your relevant
          experience, including your licenses, education and other
          qualifications? What do these qualifications mean?
        </p>
        <p className="pl-5">
          <strong className="font-italic">Response:</strong> Two types of
          financial firms that you might be considering are investment advisers
          and broker-dealers. AMA is an investment adviser.
        </p>
        <p className="pl-5">
          As an investment adviser, we are held to a fiduciary standard that
          covers our investment advisory relationship with you. As an investment
          adviser, we have both a fiduciary duty of loyalty and a fiduciary duty
          of care to our clients. Under the duty of loyalty, we must mitigate
          conflicts of interest or tell you about them in a way that you can
          understand so that you can decide whether or not to agree to them. The
          duty of loyalty requires us to place your best interests ahead of our
          own at all times. The duty of care requires us to exercise care in
          managing your portfolio and giving you advice. We must monitor your
          portfolio, investment strategy, and investments throughout the
          duration of our advisory relationship with you. Our monitoring will be
          limited to ensuring our software-based algorithm continues to buy,
          rebalance, and sell ETFs and Eligible Stocks in a way designed to
          approximate the target allocations of your Core Portfolio and Explore
          Portfolio on an ongoing basis.
        </p>
        <p className="pl-5">
          Broker-dealers must act in your best interest and not place their
          interest ahead of yours when they recommend an investment or
          investment strategy involving securities. Broker-dealers are not
          fiduciaries and are not required to monitor your portfolio or
          investments on an ongoing basis.
        </p>
        <p className="pl-5">
          Our Core Portfolio is built of exchange-traded funds (“ETFs”) that we
          believe have low costs relative to other, comparable ETFs offered in
          the marketplace. We designed the Core Portfolio to provide exposure to
          a diversified set of assets selected for a long-term “buy-and-hold”
          investment strategy and investors with an aggressive risk tolerance.
          AMA has designed the Explore Portfolio feature for you to learn,
          research, and select up to 10 individual stocks from the Eligible
          Stock List, a carefully selected list of up to 200 different large-cap
          company stocks, so you can pursue long-term buy-and-hold strategies
          for investing in individual companies with far smaller investments
          than the minimums required by traditional brokerages and financial
          institutions.
        </p>
        <p className="pl-5">
          Ant Money Advisors, LLC is a registered investment adviser with the
          Securities and Exchange Commission (“SEC”). (Our SEC registration does
          not imply a certain level of skill or training.) Your account is
          carried on a fully disclosed basis by our custodian, DriveWealth, LLC,
          which is registered as broker-dealer with the SEC, is registered with
          the states listed on FINRA’s{" "}
          <a href="https://brokercheck.finra.org/">BrokerCheck</a> website, a
          member of FINRA, and a member of SIPC. Please review our Form CRS and
          Form ADV Part 2 for more details about AMA.
        </p>
        <p className="pl-5">
          Our Chief Investment Officer, Walter W. Cruttenden, has over four
          decades of experience in the capital markets and investments industry.
          Mr. Cruttenden co-founded and currently serves as Chairman of Acorns
          Grow, Inc., a micro investing company, Chairman of Blast Intergalactic
          Group, Inc. (“BIG”), a gamification company operating at the nexus of
          the Fintech and Game industries, and co-founded Ant Money, Inc.
          (“AMI”). BIG and AMI each own half of AMA’s parent company, Ant Farm,
          Inc. Mr. Cruttenden is a licensed investment adviser representative.
          In addition, Mr. Cruttenden has passed examinations for and been
          registered in the past as a General Securities Representative and a
          General Securities Principal at a number of broker-dealer firms. For
          additional information, please consult our{" "}
          <Link to="/brochure-supplement-adv-part-2b/">Part 2B</Link> of our
          Form ADV, which provides additional important details regarding Mr.
          Cruttenden’s qualifications and experience. Mr. Cruttenden has been a
          leader in developing innovative automated investment advisory
          technologies, products, and services allowing small investors to
          benefit from automatically managed portfolios of relatively low-cost,
          diversified investments.
        </p>
        <p className="font-italic">
          <strong>Conversation starters:</strong> Help me understand how these
          fees and costs might affect my investments. If I give you $10,000 to
          invest, how much will go to fees and costs, and how much will be
          invested for me?
        </p>
        <p className="pl-5">
          <strong className="font-italic">Response:</strong> If you gave us
          $10,000 dollars to invest, we would invest the full $10,000 for you.
          When you deposit money, withdraw money, or buy/sell an Eligible Stock,
          our algorithm automatically trades a combination of ETFs and, if
          applicable, Eligible Stock purchases or sales designed to keep your
          portfolio consistent with the target allocations set in your Core and
          Explore Portfolio(s). You will not be charged commission on your
          trades or advisory fees. The ATM.com, Blast, and Learn &amp; Earn
          websites, services, and associated apps are complimentary. However,
          there may be a fee for premium features such as, Data Dating Rewards,
          Educational Courses, or Mission Rewards. More information about this
          can be found in AMA’s{" "}
          <Link to="/advisory-agreement/">Investment Advisory Agreement</Link>{" "}
          and the{" "}
          <Link to="/wrap-fee-brochure-adv-part-2a/">Wrap Fee Brochure</Link>.
        </p>
        <p className="font-italic">
          <strong>Conversation starters:</strong> How might your conflicts of
          interest affect me, and how will you address them?
        </p>
        <p className="pl-5">
          <strong className="font-italic">Response:</strong> If you have an
          account with us, we receive a portion of the revenue from our
          affiliates, ATM.com, Blast, and Learn &amp; Earn, after paying the
          custodian and other expenses. This creates incentives for us to
          encourage you to invest continuously using one of our affiliates’
          mobile apps as long as possible. These incentives might conflict with
          your interests in cases where fees and costs are less if you or your
          relatives invested for you without paying the subscription fee for one
          of our affiliates’ apps.
        </p>
        <p className="pl-5">
          AMA addresses these and any other conflicts that may arise by making
          available all of our <Link to="/legal-disclaimers/">disclosures</Link>{" "}
          on our website. AMA encourages you to read and familiarize yourself
          with all disclosures that are relevant to your investment relationship
          with us.
        </p>
        <p className="font-italic">
          <strong>Conversation starters:</strong> As a financial professional,
          do you have any disciplinary history? For what type of conduct?
        </p>
        <p className="pl-5">
          <strong className="font-italic">Response:</strong> No, AMA does not
          have a disciplinary history, nor is AMA involved in any other legal
          matters that would have a material adverse impact on its advisory
          business. Visit{" "}
          <a href="https://www.investor.gov/CRS">Investor.gov/CRS</a> for a free
          and simple search tool to research us and our financial consultants,
          or you can visit{" "}
          <a href="https://adviserinfo.sec.gov/">adviserinfo.sec.gov/</a>.
        </p>
        <p className="font-italic">
          <strong>Conversation starters:</strong> Who is my primary contact
          person? Is he or she a representative of an investment adviser or a
          broker-dealer? Who can I talk to if I have concerns about how this
          person is treating me?
        </p>
        <p className="pl-5">
          <strong className="font-italic">Response:</strong> AMA is classified
          under SEC regulations as an “internet adviser,” which means that our
          investment advice to all our clients is from computer software-based
          models or applications and that we generally offer that advice only
          through our interactive website. Because your account and our platform
          are fully integrated online through our website and our affiliated
          apps, you should direct any questions and concerns you may have by
          emailing{" "}
          <a href="mailto:support@antmoneyadvisors.com">
            support@antmoneyadvisors.com
          </a>{" "}
          or visiting{" "}
          <a href="https://antmoneyadvisors.com">
            https://antmoneyadvisors.com
          </a>
          . An appropriate member of the AMA team (“agent”) will address your
          question or concern based on how you categorize it when prompted to do
          so. The appropriate agent will provide you with a response as soon as
          possible. Agents of our Customer Support Team are not investment
          adviser representatives and are not licensed to give financial advice.
        </p>
        <hr />
        <p>
          <a href={pdfUrl} target="_blank" rel="noreferrer">
            Click here
          </a>{" "}
          to download the Form CRS Conversation Starters document.
        </p>
      </Container>
    </Layout>
  )
}

export default FormCrsConversationStartersPage
